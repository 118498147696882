




import { environment } from '@/utils/environment';
import Vue from 'vue';
import Component from 'vue-class-component';
import jwt_decode from 'jwt-decode';
import storeTypes from '@/store/types';
import { getUserRole } from '@/api/user';
import NathanModal from '@/components/NathanModal.vue';
import { SessionStorageValues } from '@/enums/session-storages-values.enum';

@Component({
  props: {
    jwt: String,
  },
  components: {
    NathanModal,
  },
})
export default class AppCallback extends Vue {
  loggedIn = false;
  showEmployeeEntryPointModal = false;
  devAccessToken = () => environment().devAccessTokenAdmin;

  async mounted(): Promise<any> {
    localStorage.clear();
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    let jwt: any = null;
    let isRefreshingToken = false;

    if (
      (!this.$props.jwt || this.$props.jwt === '') &&
      window.location.hash.length === 0
    ) {
      // On production the usage of dev tokens won't work
      if (!environment().isProduction) {
        jwt = this.devAccessToken();
        console.info('Using temponary dev token');
      }
    } else {
      jwt = this.$props.jwt;
    }

    // Check if we are refreshing the authentication via token in hash
    if (!jwt) {
      const hash = window.location.hash;
      if (hash !== '') {
        const authToken = hash.replace('#id_token=', '');
        jwt = authToken;
        isRefreshingToken = true;
      }
    }

    if (jwt && jwt !== '') {
      if (isRefreshingToken) {
        localStorage.setItem('refreshedToken', jwt);
      }
      this.$store.commit(storeTypes.mutations.SET_JWT, jwt);
      getUserRole()
        .then((res) => {
          const role = res.data.role;
          const decodedJWT = jwt_decode(jwt);

          const userInformation: any = {
            name: decodedJWT.name,
            email:
              decodedJWT.emails?.length > 0 ? decodedJWT.emails[0] : undefined,
            role,
          };

          if (!isRefreshingToken) {
            this._freshLogin(userInformation);
          } else {
            this._refreshSessionLogin(userInformation);
          }
        })
        .catch(() => {
          this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
          this._redirectToForbidden();
        });
    } else {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
      this._redirectToForbidden();
    }
  }

  private _freshLogin(userInformation: any): void {
    this.$store.commit(
      storeTypes.mutations.SET_USER_INFORMATION,
      userInformation
    );

    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    this._navigateAfterLogin();
  }

  private _refreshSessionLogin(userInformation: any): void {
    const routeName = sessionStorage.getItem(
      SessionStorageValues.RouteBeforeRefresh
    );

    this.$store.dispatch(
      storeTypes.actions.RETRIEVE_USER_INPUT_FROM_SESSION_STORAGE
    );
    this.$store.dispatch(
      storeTypes.mutations.SET_USER_INFORMATION,
      userInformation
    );
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    if (routeName && !routeName.includes('403')) {
      this.$router.push({ name: routeName });
    } else {
      this._navigateAfterLogin();
    }
  }

  private _navigateAfterLogin(): void {
    this.loggedIn = true;
    this.$router.push({ name: 'InqueryOverview' });
  }

  private _redirectToForbidden() {
    try {
      this.$router.push({ name: '403' });
    } catch (error) {
      console.error(error);
    }
  }
}
