





















import Vue from 'vue';
import Component from 'vue-class-component';
import Header from './components/Header.vue';
import NathanHelper from './utils/nathan-helper';
import Loader from '@/components/Loader.vue';
import storeTypes from './store/types';
import DevToolbar from './components/DevToolbar.vue';
import { getState } from './utils/vueInstance';

@Component({
  components: {
    Header,
    Loader,
    DevToolbar,
  },
})
export default class App extends Vue {
  get showDevTools(): boolean {
    return !!getState().environment?.devAccessToken && !this.devToolsClosed;
  }

  windowWidth = window.innerWidth;
  devToolsClosed = false;

  mounted(): void {
    this._commitIsMobile();

    this.$watch('windowWidth', () => {
      this._commitIsMobile();
    });

    this.$nextTick(() => {
      window.addEventListener(
        'resize',
        () => (this.windowWidth = window.innerWidth)
      );
    });
  }

  get isMobile(): boolean {
    return this.$store.state.isMobile;
  }

  private _commitIsMobile(): void {
    this.$store.commit(storeTypes.mutations.SET_WINDOW_WIDTH, this.windowWidth);
    this.$store.commit(
      storeTypes.mutations.CHANGE_IS_MOBILE,
      NathanHelper.isMobile()
    );
  }
}
