export enum DistributorType {
  standard = 'standard',
  cityHeating = 'cityHeating',
  heatingAndCooling = 'heatingAndCooling',
}

export enum EnergySuppliers {
  essent = 'Essent',
  vattenfallEneco = 'Vattenfall / Eneco',
}

export enum ConnectionTypes {
  threePipe = '3pipe',
  fourPipe = '4pipe',
}

export enum InputDirections {
  left = 'left',
  right = 'right',
}

export enum FlowRates {
  low = '25 - 135 L/H',
}
