const storeTypes = {
  mutations: {
    SET_CONFIGURATIONS: 'SET_CONFIGURATIONS',
    SET_INQUERY: 'SET_INQUERY',
    SET_SELECTED_ADMIN_INQUERY: 'SET_SELECTED_ADMIN_INQUERY',
    CHANGE_IS_MOBILE: 'CHANGE_IS_MOBILE',
    SET_USER_INFORMATION: 'SET_USER_INFORMATION',
    SET_SHOW_LOADER: 'SET_SHOW_LOADER',
    SET_WINDOW_WIDTH: 'SET_WINDOW_WIDTH',
    SET_JWT: 'SET_JWT',
    SET_SELECTED_CONFIGURATOR_STEP: 'SET_SELECTED_CONFIGURATOR_STEP',
    SET_ENVIRONMENT: 'SET_ENVIRONMENT',
  },
  actions: {
    CREATE_AND_SELECT_NEW_CONFIGURATION: 'CREATE_AND_SELECT_NEW_CONFIGURATION',
    UPDATE_SELECTED_INQUERY: 'UPDATE_SELECTED_INQUERY',
    CHANGE_CURRENTLY_SELECTED_CONFIGURATION:
      'CHANGE_CURRENTLY_SELECTED_CONFIGURATION',
    MUTATE_CONFIGURATION_BY_ID: 'MUTATE_CONFIGURATION_BY_ID',
    MUTATE_CURRENTLY_SELECTED_CONFIGURATION:
      'MUTATE_CURRENTLY_SELECTED_CONFIGURATION',
    REMOVE_CONFIG: 'REMOVE_CONFIG',
    MUTATE_INQUERY: 'MUTATE_INQUERY',
    POST_PUT_INQUERY: 'POST_PUT_INQUERY',
    TOGGLE_LOADER: 'TOGGLE_LOADER',
    RESET_CONFIGURATOR_STATE: 'RESET_CONFIGURATOR_STATE',
    RETRIEVE_USER_INPUT_FROM_SESSION_STORAGE:
      'RETRIEVE_USER_INPUT_FROM_SESSION_STORAGE',
    SAVE_USER_INPUT_TO_SESSION_STORAGE: 'SAVE_USER_INPUT_TO_SESSION_STORAGE',
    EDIT_SELECTED_INQUERY: 'EDIT_SELECTED_INQUERY',
  },
  getters: {
    GET_CURRENTLY_SELECTED_CONFIGURATION:
      'GET_CURRENTLY_SELECTED_CONFIGURATION',
  },
};

export default storeTypes;
