import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import loadEnvironment from './utils/load-config';
import i18n from './i18n';
import PortalVue from 'portal-vue'
import { ToastPlugin } from 'bootstrap-vue/src/components/toast';
import { ModalPlugin } from 'bootstrap-vue';
import { initInterceptor } from './utils/http-interceptor';
import vSelect from 'vue-select';
import { initEventBusListners } from './utils/event-bus';
import storeTypes from './store/types';
import { IEnvironment } from './utils/environment';
import { getVueInstance, setVueInstance } from './utils/vueInstance';

Vue.component('v-select', vSelect)

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.component('v-select', vSelect);

loadEnvironment().then((environment: IEnvironment) => {
  setVueInstance(new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }));

  getVueInstance()
    .$store
    .commit(storeTypes.mutations.SET_ENVIRONMENT, environment);

  initInterceptor();

  getVueInstance()
    .$mount('#app');

  initEventBusListners();
});


