import { IDistributorUnitType } from '@/typing/configuration.interface';
export default class ConfigurationHelper {
  static determineGroupsToAdd(groups: number): number[] {
    /*
      BUSSINESS RULE:
      Before this implementation the amount of groups in a zone was limited to 4 (a.k.a maxGroups).
      This is not applicable anymore and its was requested to let the user be able to add the maximum amount of zones to one "zone".
      This function is ran when the user adds more than 4 groups to a zone.

      - it calculates the amount of zones neccasary
      - determine the sizes of the groups
      - after the determinasion it applies another business rule. Which is optimizing the "zones". This is due to the fact that I.E:

      You've got 5 groups selected for a zone. Normally you would say that 2 zones which consist of 4 groups and 1 group (4 + 1) = 5;
      This is not the effiecient way to install these systems. So it's requested that when a client adds these amount of groups that we try to distibute them evenly over the zones we are populating.

      Ticket: NTH-88

      NOTE: this works properly considering the max amount of groups in a zone is 4, any number lower or higher is not properly tested.
    */

    const maxGroups = 4;

    const determineSpread = (toGroup: number) => {
      let spreadableWith = 0;

      for (let i = 2; i <= maxGroups; i++) {
        const evenlyDividableWith = toGroup / i;
        if (
          evenlyDividableWith === Math.floor(evenlyDividableWith) &&
          evenlyDividableWith <= 3
        ) {
          spreadableWith = i;
        }
      }

      return spreadableWith;
    };

    if (groups <= maxGroups) {
      // If the limit of groups is not exceeded then your safe to add just 1 zone.
      return [groups];
    }

    const x = groups / maxGroups;
    const amountOfGroupsToPush = Math.ceil(x);
    const spreadableWith = determineSpread(groups);

    let toAddGroups = [];

    for (let i = 0; i < amountOfGroupsToPush; i++) {
      if (spreadableWith > 0) {
        toAddGroups.push(spreadableWith);
      } else if (x >= i + 1) {
        toAddGroups.push(maxGroups);
      } else {
        const amountToAdd = maxGroups * (x - Math.floor(x));
        toAddGroups.push(amountToAdd);
      }
    }

    if (spreadableWith === 0) {
      // No need to reverse check the array when its evenly spread.
      const reversedArray = toAddGroups.reverse();

      if (reversedArray.length > 0) {
        reversedArray.some((num, i) => {
          if (i === reversedArray.length - 1) {
            // No need to check the last one in the array, otherwise you would get an array out of bounds execption.
            return true;
          } else {
            const diff = reversedArray[i + 1] - num;
            if (diff >= 2) {
              reversedArray[i + 1] -= diff / 2;
              reversedArray[i] += diff / 2;
              reversedArray[i + 1] = Math.ceil(reversedArray[i + 1]);
              reversedArray[i] = Math.floor(reversedArray[i]);
              return true;
            }
          }
        });
      }

      toAddGroups = reversedArray.reverse();
    }

    return toAddGroups;
  }

  // TODO: Translate returned values
  static getRealLifeInputDirectionByDistributorType(d: IDistributorUnitType): string {
    if (d.type === '4801' || d.type === '4803' || d.type === '4851' || d.type === '4861') {
      return 'Boven';
    }

    if (d.type === '4800' || d.type === '4802' || d.type === '4880' || d.type === '4885' || d.type === '4850' || d.type === '4860') {
      return 'Onder';
    }

    if (d.type === '4010' || d.type === '4100') {
      return d.inputDirection as string;
    }

    return '';
  }
}
