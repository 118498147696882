






















import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    showFooter: {
      default: true,
      type: Boolean,
    },
    closeOnBackdropClick: Boolean,
    showCloseButton: Boolean,
    headerTitle: String,
    slotData: {
      default: Object,
    },
  },
})
export default class NathanModal extends Vue {
  showModal = false;

  mounted() {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;

    setTimeout(() => {
      this.$emit('close');
    }, 500);
  }

  backdropClick(): void {
    if (this.$props.closeOnBackdropClick) this.closeModal();
  }

  emitChange(changeName: string, value: any): void {
    this.$emit('change', { changeName, value });
  }
}
