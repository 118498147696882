import { ToastrVariant } from '@/enums/toastr-type.enum';

export default class ToastrHelper {
    static successToastr = (message: string, vueInstance: any): void => {
        ToastrHelper.createToastr(message, ToastrVariant.Success, vueInstance);
    };
    
    static errorToastr = (message: string, vueInstance: any): void => {
        ToastrHelper.createToastr(message, ToastrVariant.Error, vueInstance);
    };

    static warningToastr = (message: string, vueInstance: any): void => {
        ToastrHelper.createToastr(message, ToastrVariant.Warning, vueInstance);
    };
    
    static createToastr = (message: string, variant: ToastrVariant.Success | ToastrVariant.Error | ToastrVariant.Warning, vueInstance: any): void => {
        vueInstance.$bvToast.toast('THIS IS NOT SHOWN', {
            bodyClass: 'hidden',
            title: message,
            solid: true,
            variant: variant
        });
    }
}