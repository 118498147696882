

























































































































































import { IInquery } from '@/typing/inquery.interface';
import NathanModal from '@/components/NathanModal.vue';
import { Component, Vue } from 'vue-property-decorator';
import ConfigurationListItem from '@/components/ConfigurationListItem.vue';
import InqueryDetails from '@/components/InqueryDetails.vue';
import {
  getConfigurationsStickersPDF,
  getConfigurationStickerPDF,
} from '@/api/configurations';
import storeTypes from '@/store/types';
import { State } from '@/typing/state';
import { Roles } from '@/enums/role.enum';
import { InquiryStatus } from '@/enums/inquiry-status.enum';
import NathanHelper from '@/utils/nathan-helper';

@Component({
  components: {
    InqueryDetails,
    ConfigurationListItem,
    NathanModal,
  },
})
export default class AdminInquery extends Vue {
  inqueryStatusModalShown = false;
  editedInqueryStatus = '';
  editedInqueryRemark = '';
  editedInqueryRemarkPublic = false;
  nathanHelper = NathanHelper;

  // TODO: Translate
  statusSelectOptions = [
    {
      label: NathanHelper.getInquiryStatusDescription(InquiryStatus.Created),
      value: InquiryStatus.Created,
    },
    {
      label: NathanHelper.getInquiryStatusDescription(InquiryStatus.Ordered),
      value: InquiryStatus.Ordered,
    },
  ];

  get isAdmin(): boolean {
    return (
      (this.$store.state as State).userInformation?.role === Roles.employee
    );
  }

  get inquery(): IInquery | null {
    return Object.assign({}, this.$store.state.selectedAdminInquery);
  }

  mounted(): void {
    if (!this.inquery || JSON.stringify(this.inquery) === '{}') {
      this.backToOverview();
    }
  }

  backToOverview(): void {
    this.$router.push({ name: 'InqueryOverview' });
  }

  editInquery(): void {
    this.$store.dispatch(storeTypes.actions.EDIT_SELECTED_INQUERY);
    this.$router.push({ name: 'Configurator' });
  }

  handleModalChanges(e: any): void {
    switch (e.changeName) {
      case 'editedStatus':
        this.editedInqueryStatus = e.value;
        break;
      case 'editedRemark':
        this.editedInqueryRemark = e.value;
        break;
      case 'editedRemarkPublic':
        this.editedInqueryRemarkPublic = e.value;
        break;
    }
  }

  async downloadSticker(id: any): Promise<any> {
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    try {
      const pdf = await getConfigurationStickerPDF(id);
      window.open(pdf.data.url);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  async downloadStickers(): Promise<any> {
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    try {
      const pdf = await getConfigurationsStickersPDF(this.inquery?.id ?? -1);
      window.open(pdf.data.url);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  handleEditRemarkStatus(): void {
    this.inqueryStatusModalShown = true;
    this.editedInqueryStatus = this.inquery?.status ?? '';
    this.editedInqueryRemark = this.inquery?.remark ?? '';
    this.editedInqueryRemarkPublic = this.inquery?.publicRemark ?? false;
  }

  async saveSelectedInquery(updatedModel: IInquery): Promise<void> {
    await this.$store.dispatch(
      storeTypes.actions.UPDATE_SELECTED_INQUERY,
      updatedModel
    );
  }

  async closeInqueryStatusModal(saveChanges: boolean): Promise<void> {
    if (!this.inquery) return;
    const modalRef = this.$refs.inqueryStatusModal;
    const updatedModel: IInquery = { ...this.inquery };

    if (saveChanges) {
      updatedModel.status = this.editedInqueryStatus;
      updatedModel.remark = this.editedInqueryRemark;
      updatedModel.publicRemark = this.editedInqueryRemarkPublic;
      try {
        await this.saveSelectedInquery(updatedModel);
        this.inquery.status = this.editedInqueryStatus;
        this.inquery.remark = this.editedInqueryRemark;
        this.inquery.publicRemark = this.editedInqueryRemarkPublic;
        this.$store.commit(
          storeTypes.mutations.SET_SELECTED_ADMIN_INQUERY,
          this.inquery
        );
      } catch (error) {
        // return;
      }
    } else {
      this.editedInqueryStatus = '';
      this.editedInqueryRemark = '';
      this.editedInqueryRemarkPublic = false;
    }

    (modalRef as NathanModal).closeModal();
  }
}
