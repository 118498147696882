import { IConfiguration } from '@/typing/configuration.interface';
import { IInquery } from '@/typing/inquery.interface';
import { State } from '@/typing/state';
import { IEnvironment } from '@/utils/environment';

const mutations = {
    SET_CONFIGURATIONS: (state: State, val: IConfiguration[]) => state.configurations = val,
    SET_SELECTED_CONFIGURATOR_STEP: (state: State, val: any) => state.selectedConfiguratorStepName = val,
    SET_INQUERY: (state: State, val: IInquery) => state.inquery = Object.assign({}, val),
    SET_SELECTED_ADMIN_INQUERY: (state: State, val: IInquery) => state.selectedAdminInquery = Object.assign({}, val),
    SET_SHOW_LOADER: (state: State, val: boolean) => state.showLoader = val,
    CHANGE_IS_MOBILE: (state: State, val: boolean) => state.isMobile = val,
    SET_USER_INFORMATION: (state: State, val: any) => state.userInformation = val,
    SET_JWT: (state: State, val: string) => state.jwt = val,
    SET_WINDOW_WIDTH: (state: State, val: number) => state.windowWidth = val,
    SET_ENVIRONMENT:(state: State, val: IEnvironment) => state.environment = val
}

export default mutations;