





































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import draggable from 'vuedraggable';
import ConfigurationSegment from './ConfigurationSegment.vue';

@Component({
  props: {
    zones: Array,
    rightsided: Boolean,
    showDistributorImg: {
      default: false,
    },
  },
  components: {
    draggable,
    ConfigurationSegment,
  },
})
export default class ConfigurationZone extends Vue {
  dragging = false;
  currentlyDraggingIndex = -1;
  shownRevision = 0;
  shouldShowPipe = true;

  shownZones: any[] = [];

  private _zonesBackup: any = [];

  get isMobile(): boolean {
    return this.$store.state.isMobile;
  }

  get windowWidth(): any {
    return this.$store.state.windowWidth;
  }

  mounted() {
    if (this.$props.zones) {
      this._zonesBackup = [...this.$props.zones];
      this._setShownZones([...this.$props.zones]);
    }

    this.$watch('zones', (newValue) => {
      this._zonesBackup = [...newValue];
      this._setShownZones([...newValue]);
      this.$forceUpdate();
    });

    this.$watch('rightsided', () => {
      if (this._zonesBackup.length > 0) {
        this._setShownZones(this._zonesBackup);
      }
    });

    this.$nextTick(() => {
      if (this.$props.rightsided) {
        this.scrollFullyToRight();
      }
    });
  }

  onScroll(e: any): void {
    if (!this.$props.rightsided) {
      this.shouldShowPipe = e.target.scrollLeft === 0;
    } else {
      this.shouldShowPipe =
        e.target.scrollWidth - e.target.scrollLeft - e.target.offsetWidth < 0;
    }
  }

  scrollFullyToRight(): void {
    const el = this.$refs.zoneSection as HTMLElement;
    el.scrollLeft = 999999999;
  }

  startDrag(e: any) {
    this.dragging = true;
    this.currentlyDraggingIndex = e.oldIndex;
  }

  endDrag(e: any): void {
    this.dragging = false;

    if (
      e?.originalEvent?.target?.className === 'remove-item-drag' ||
      e?.originalEvent?.target?.className === 'remove-item-content'
    ) {
      const zones = [...this._zonesBackup];

      let toSpliceIndex = -1;
      toSpliceIndex = this.currentlyDraggingIndex;

      zones.splice(toSpliceIndex, 1);
      this.emitChanges(zones);
    } else {
      this.emitChanges();
    }
    this.currentlyDraggingIndex = -1;
  }

  emitChanges(zonesToEmit?: any): void {
    let zones = null;

    if (zonesToEmit) {
      zones = zonesToEmit;
    } else {
      zones = [...this.shownZones];
    }

    this.$emit('zonesChanged', zones);
  }

  private _setShownZones(zones: any[]): void {
    if (zones.length === 0) {
      this.shownZones = [];
      return;
    }

    this.shownZones = [...zones];
  }
}
