import storeTypes from '@/store/types';
import Vue from 'vue';
import { refreshToken } from './refresh-token';
import { getVueInstance } from './vueInstance';

export const EventBus = new Vue();

export enum EventBusEvent {
  RefreshToken = 'RefreshToken',
  ToggleLoader = 'ToggleLoader'
}

export function initEventBusListners(): void {
  EventBus.$on(EventBusEvent.RefreshToken, () => refreshToken(getVueInstance()));
  EventBus.$on(EventBusEvent.ToggleLoader, () => getVueInstance().$store.dispatch(storeTypes.actions.TOGGLE_LOADER));
}
