

















































































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    initialValue: {
      required: false,
    },
    optionsDataCheck: {
      required: false,
    },
    name: {
      required: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    options: Array,
  },
})
export default class RadioOptions extends Vue {
  window = window;

  mounted() {
    this._processInitialValue();
    this.$watch('initialValue', () => {
      this._processInitialValue();
    });
  }

  selectOption(optionIndex: number): void {
    const options = [...this.$props.options];
    options.forEach((option: any, i: number) => {
      if (i === optionIndex) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    });

    const valueOfSelectedOption = this.$props.options[optionIndex].value;

    this.$emit('changeOptions', options);
    this.$emit('input', valueOfSelectedOption);

    if (this.$props.name) {
      this.$emit('checkOptions', this.$props.name);
    }
  }

  shouldShowOption(o: any): boolean {
    if (!this.$props.optionsDataCheck || !o.showOption) {
      return true;
    }

    return o.showOption(this.$props.optionsDataCheck);
  }

  private _processInitialValue(): void {
    const initialValue = JSON.stringify(this.$props.initialValue);

    this.$props.options.forEach((o: any) => {
      o.selected = false;
    });

    if (initialValue !== undefined) {
      this.$props.options.some((o: any, i: number) => {
        if (JSON.stringify(o.value) === initialValue) {
          this.selectOption(i);
          return true;
        }
      });
    }
  }
}
