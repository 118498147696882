import { InquiryStatus } from '@/enums/inquiry-status.enum';
import { SessionStorageValues } from '@/enums/session-storages-values.enum';
import { IEnvironment } from '@/utils/environment';
import { IConfiguration } from './configuration.interface';
import { IInquery } from './inquery.interface';

export interface ISessionStorageSavedData {
  configurations?: IConfiguration[];
  selectedAdminInquery?: IInquery;
  inquery?: IInquery;
  selectedConfiguratorStepName: any;
}

export const initialInquery = {
  id: undefined,
  name: '',
  address: '',
  city: '',
  email: '',
  projectName: '',
  configurations: undefined,
  status: InquiryStatus.Created,
  remark: undefined,
  publicRemark: false,
};

export const initialStep = { step: 'intro', stepData: {} };

export class State {
  isMobile?: boolean;
  configurations?: IConfiguration[];
  selectedAdminInquery?: IInquery;
  showLoader?: boolean;
  windowWidth?: any;
  inquery?: IInquery;
  userInformation?: any;
  jwt?: string;
  selectedConfiguratorStepName: any;
  environment?: IEnvironment;

  constructor(state?: State) {
    this.isMobile = state?.isMobile ?? false;
    this.configurations = state?.configurations ?? [];
    this.selectedAdminInquery = state?.selectedAdminInquery ?? ({} as IInquery);
    this.showLoader = false;
    this.windowWidth = undefined;
    this.selectedConfiguratorStepName =
      state?.selectedConfiguratorStepName ?? initialStep;
    this.inquery = state?.inquery ?? initialInquery;
    this.userInformation = state?.userInformation ?? {
      name: undefined,
      email: undefined,
      role: undefined,
    };
    this.jwt = state?.jwt ?? undefined;
    this.environment = undefined;
  }

  saveUserInputToSessionStorage(): void {
    const savedUserData: ISessionStorageSavedData = {
      configurations: this.configurations,
      selectedAdminInquery: this.selectedAdminInquery,
      inquery: this.inquery,
      selectedConfiguratorStepName: this.selectedConfiguratorStepName,
    };

    sessionStorage.setItem(
      SessionStorageValues.SavedUserData,
      JSON.stringify(savedUserData)
    );
  }
}
