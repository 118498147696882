
























































































import Vue from 'vue';
import { IConfiguration } from '@/typing/configuration.interface';
import Component from 'vue-class-component';
import ConfigurationListItem from '@/components/ConfigurationListItem.vue';
import storeTypes from '@/store/types';
import NathanModal from '@/components/NathanModal.vue';

@Component({
  components: {
    ConfigurationListItem,
    NathanModal,
  },
  props: {
    onLoad: Function,
  },
})
export default class ConfigurationsOverviewStep extends Vue {
  showRemoveModal = false;
  private _toRemoveConfigurationId: number | null = null;

  get configurations(): IConfiguration {
    return this.$store.state.configurations;
  }

  mounted(): void {
    if (this.$props.onLoad) {
      setTimeout(() => {
        this.$props.onLoad();
      }, 200);
    }
  }

  newConfig(): void {
    this.$store.dispatch(
      storeTypes.actions.CREATE_AND_SELECT_NEW_CONFIGURATION
    );
    this.$emit('changeStep', { step: 'intro' });
  }

  selectAndEdit(c: IConfiguration): void {
    this.$store.dispatch(
      storeTypes.actions.CHANGE_CURRENTLY_SELECTED_CONFIGURATION,
      c.id
    );
    this.$emit('changeStep', { step: 'intro' });
  }

  openRemoveModal(c: IConfiguration): void {
    this.showRemoveModal = true;
    this._toRemoveConfigurationId = c.id ?? 0;
  }

  removeConfiguration(): void {
    if (this._toRemoveConfigurationId !== null) {
      this.$store.dispatch(
        storeTypes.actions.REMOVE_CONFIG,
        this._toRemoveConfigurationId
      );
    }

    if (this.$store.state.configurations.length === 0) {
      this.$emit('changeStep', { step: 'intro' });
    }
  }

  finalizeOrder(): void {
    this.$emit('changeStep', { step: 'inquery' });
  }
}
