export function environment(): IEnvironment {
  return (document.getElementById('app') as any).__vue__.$store.state
    .environment;
}
export const apiBaseUrl = () => (environment() as IEnvironment).apiBaseUrl;
export const isProduction = () => (environment() as IEnvironment).isProduction;
export const devAccessToken = () =>
  (environment() as IEnvironment).devAccessToken;
export const devAccessTokenAdmin = () =>
  (environment() as IEnvironment).devAccessTokenAdmin;
export const authRedirectUrl = () =>
  (environment() as IEnvironment).authRedirectUrl;
export const mijnNathanClientId = () =>
  (environment() as IEnvironment).mijnNathanClientId;
export const refreshTokenUrl = () => {
  const authUrl = authRedirectUrl()
    .replace(
      '{REPLACEURL}',
      `${encodeURIComponent(window.location.origin)}/auth`
    )
    .replace('{REPLACECLIENTID}', mijnNathanClientId());
  return authUrl;
};

export interface IEnvironment {
  envName: string;
  apiBaseUrl: string;
  isProduction: boolean;
  devAccessToken: string;
  devAccessTokenAdmin: string;
  authRedirectUrl: string;
  mijnNathanClientId: string;
}
