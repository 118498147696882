



































































































































































































































import Component from 'vue-class-component';
import ConfigurationSegment from '@/components/Configuration/ConfigurationSegment.vue';
import ZoneSegmentsAmountUI from '@/components/Configuration/ZoneSegmentsAmountUI.vue';
import {
  IConfiguration,
  IDistributorUnitType,
} from '@/typing/configuration.interface';
import NathanHelper from '@/utils/nathan-helper';
import storeTypes from '@/store/types';
import ConfigurationHelper from '@/utils/configuration-helper';
import BaseComponent from './BaseComponent';

@Component({
  props: {
    configuration: {
      required: false,
    },
    isAdmin: Boolean,
    canSetAmount: Boolean,
    index: Number,
  },
  components: {
    ConfigurationSegment,
    ZoneSegmentsAmountUI,
  },
})
export default class ConfigurationListItem extends BaseComponent {
  amount = 1;

  get isMobile(): boolean {
    return this.$store.state.isMobile;
  }

  get shownZones(): any {
    const config: IConfiguration = this.$props.configuration;

    if (!config || !config.zoneGroups) return [];

    return NathanHelper.getZonesFromZoneGroups(this.$props.configuration);
  }

  getAmountOfGroupsUsed(config: IConfiguration) {
    return NathanHelper.getAmountOfGroupsUsedFromConfiguration(config);
  }

  configHasKnownAddress(): boolean {
    return this.$props.configuration.addressKnown;
  }

  editConfig(): void {
    this.$emit('editConfig');
  }

  removeConfig(): void {
    this.$emit('removeConfig');
  }

  onAmountChange(newAmount: any): void {
    const config = Object.assign({}, this.$props.configuration);
    config.amount = newAmount;
    this.$store.dispatch(storeTypes.actions.MUTATE_CONFIGURATION_BY_ID, {
      id: this.$props.configuration.id,
      config,
    });
  }

  getRealInputDirection(d: IDistributorUnitType): string {
    return ConfigurationHelper.getRealLifeInputDirectionByDistributorType(d);
  }

  configIsRightsided(c: IConfiguration): boolean {
    if (!c.distributorUnitType || !c.distributorUnitType.inputDirection)
      return false;

    return NathanHelper.configurationIsRightsided(
      (c.distributorUnitType as any).inputDirection
    );
  }
}
