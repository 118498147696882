import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { State } from '@/typing/state';

Vue.use(Vuex);

export const getInitialState: any = () => new State();

export default new Vuex.Store({
  state: getInitialState(),
  mutations,
  actions,
  getters
})
