import { IConfiguration } from '@/typing/configuration.interface';

const getters = {
  GET_CURRENTLY_SELECTED_CONFIGURATION: (state: any) => {
    const filtered = (state.configurations as any).filter(
      (c: IConfiguration) => c.currentlySelected
    );
    return filtered.length > 0 ? filtered[0] : null;
  }
};

export default getters;