import {
  IConfiguration,
  IZone,
  IZoneGroup,
} from '@/typing/configuration.interface';
import { InputDirections } from '@/enums/configuration-options.enum';
import { InquiryStatus } from '@/enums/inquiry-status.enum';

export default class NathanHelper {
  static isMobile(): boolean {
    const clientWidth = document.querySelector('body')?.clientWidth;

    if (!clientWidth) return false;

    return clientWidth < 1024;
  }

  static getZoneNames(): string[] {
    return [
      'bathroom',
      'storage_room',
      'scullery',
      'dining_room',
      'hallway',
      'kitchen',
      'zone',
      'nursery',
      'patio',
      'bedroom',
      'study_room',
      'toilet',
      'washroom',
      'office',
      'living_room',
      'attic',
      'other',
      'Zone',
    ];
  }

  static translateZoneNames(zones: any, vueInstance: Vue): any {
    return zones.map((z: any) => {
      const translateKey = `zone_configurator.zone_names.${z.name}`;
      const translatedValue = vueInstance.$t(translateKey) as string;
      const name = translateKey === translatedValue ? z.name : translatedValue;

      return {
        name,
        amount: z.amount,
      };
    });
  }

  static arrayMoveIndex(arr: any[], oldIndex: number, nexIndex: number) {
    if (nexIndex >= arr.length) {
      let k = nexIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(nexIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  static configurationIsRightsided(directionValue: string): boolean {
    return directionValue.toLowerCase() === InputDirections.right.toLowerCase();
  }

  static getZonesFromZoneGroups(config?: IConfiguration): IZone[] {
    const zoneGroups = [...(config?.zoneGroups ?? [])];

    return zoneGroups.reduce((arr: any[], currentValue: IZoneGroup) => {
      if (currentValue.zones) {
        return arr.concat([...currentValue.zones]);
      }

      return arr;
    }, []);
  }

  static getAmountOfGroupsUsedFromConfiguration(
    config: IConfiguration | any
  ): number {
    if (!config || !config.zoneGroups || config.zoneGroups.length === 0) {
      return 0;
    }

    const zones = this.getZonesFromZoneGroups(config);

    return (zones as IZone[]).reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue.amount,
      0
    );
  }

  static getInquiryStatusDescription(
    status: InquiryStatus.Created | InquiryStatus.Ordered
  ): string {
    if (status === InquiryStatus.Created) {
      return 'Configuratie(s) gemaakt';
    } else if (status === InquiryStatus.Ordered) {
      return 'Besteld door groothandel bij Nathan, wijzigingen in configuratie niet meer mogelijk';
    }

    return '';
  }

  static canEditInquiry(
    inquiryStatus: InquiryStatus.Created | InquiryStatus.Ordered
  ): boolean {
    return inquiryStatus === InquiryStatus.Created;
  }
}
