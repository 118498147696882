

































































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    rightsided: Boolean,
    amountOfSegments: Number,
    zoneName: String,
    isMobile: Boolean,
    isDragged: Boolean,
    showZoneName: {
      default: true,
      type: Boolean,
    },
    previewSize: {
      required: false,
      type: Boolean,
    },
    scollerSize: {
      required: false,
      type: Boolean
    },
    imgWidth: {
      required: false,
      type: Number
    },
    showPadding: {
      default: true
    }
  },
})
export default class ConfigurationSegment extends Vue {
  get segmentImages(): string[] {
    const imageURLs: string[] = [];
    const amount = this.$props.amountOfSegments;

    if (!amount) return [];

    if (amount === 1) {
      imageURLs.push(this.getImageLocation("e", false));
    }

    if (amount === 2) {
      imageURLs.push(this.getImageLocation("zv"));
      imageURLs.push(this.getImageLocation("es"));
    }

    if (amount === 3) {
      imageURLs.push(this.getImageLocation("zv"));
      imageURLs.push(this.getImageLocation("zs", false));
      imageURLs.push(this.getImageLocation("es"));
    }

    if (amount === 4) {
      imageURLs.push(this.getImageLocation("zv"));
      imageURLs.push(this.getImageLocation("zs", false));
      imageURLs.push(this.getImageLocation("zs", false));
      imageURLs.push(this.getImageLocation("es"));
    }

    if (this.$props.rightsided) {
      imageURLs.reverse();
    }

    return imageURLs;
  }

  getImageLocation(type: string, useRightSide = true) {
    return `${window.location.origin}/img/segment_${type}${
      this.$props.rightsided && useRightSide ? "_r" : ""
    }.svg`;
  }

  getSegmentName(zoneName: string): string {
    const translateKey = `zone_configurator.zone_names.${zoneName}`;
    const translatedValue = this.$t(translateKey) as string;

    if (translatedValue === translateKey) return zoneName;
    return translatedValue;
  }
}
