


















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { IInquery } from '@/typing/inquery.interface';
import storeTypes from '@/store/types';
import { IAPIResponse } from '@/typing/api-response.interface';
import ToastrHelper from '@/utils/toastr-helper';
import InformationButton from '@/components/InformationButton.vue';
import stepScrollDown from '@/utils/step-scroll-down-oninit';

@Component({
  components: {
    InformationButton,
  },
})
export default class InqueryStep extends Vue {
  inquery: IInquery | any = {};
  formErrors: string[] = [];
  render = false;

  mounted(): void {
    this.inquery = this.$store.state.inquery;
    this.render = true;
    if (
      this.$store.state.configurations &&
      this.$store.state.configurations.length > 0
    ) {
      this.inquery.projectName = this.$store.state.configurations[0].clientName;
    } else {
      this.inquery.projectName = '';
    }

    if (this.$store.state.userInformation.name) {
      (this.inquery as IInquery).name = this.$store.state.userInformation.name;
    }

    if (this.$store.state.userInformation.email) {
      (this.inquery as IInquery).email =
        this.$store.state.userInformation.email;
    }
  }

  async submit(e: Event): Promise<void> {
    e.preventDefault();

    if (this.canSubmit()) {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
      this.$store.dispatch(storeTypes.actions.MUTATE_INQUERY, this.inquery);
      const result: IAPIResponse = await this.$store.dispatch(
        storeTypes.actions.POST_PUT_INQUERY,
        { vm: this }
      );

      if (!(result instanceof Error)) {
        this.$emit('changeStep', {
          step: 'inqueryFinalized',
          data: {
            id: result.data.id,
            hasEdited: !!(this.inquery as IInquery).id,
          },
        });
      } else {
        if (result.statusCode !== 401) {
          ToastrHelper.errorToastr(
            'Er is iets misgegaan, contacteer de administrator.',
            this
          );
        }
      }

      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  canSubmit(): boolean {
    const formError = (field: string): any =>
      this.$t('form_error_is_required', {
        field: this.$t(`form_field_${field}`),
      });

    this.formErrors = [];

    if (!this.inquery) {
      return false;
    }

    if (!this.inquery.name || this.inquery.name.length < 0) {
      this.formErrors.push(formError('name'));
    }

    if (!this.inquery.address || this.inquery.address.length < 0) {
      this.formErrors.push(formError('address'));
    }

    if (!this.inquery.city || this.inquery.city.length < 0) {
      this.formErrors.push(formError('city'));
    }

    if (!this.inquery.email || this.inquery.email.length < 0) {
      this.formErrors.push(formError('email'));
    }

    if (!this.inquery.projectName || this.inquery.projectName.length < 0) {
      this.formErrors.push(formError('projectName'));
    }

    if (
      this.inquery.filename &&
      !/^[a-zA-Z0-9\s]+$/.test(this.inquery.filename)
    ) {
      this.formErrors.push(
        `${this.$t(
          'form_field_filename'
        )} is niet correct, gebruik geen speciale tekens in de naam.`
      );
    }

    return this.formErrors.length === 0;
  }

  back(): void {
    this.$emit('changeStep', { step: 'finalize', onload: stepScrollDown });
  }

  onEnterInput(e: KeyboardEvent): void {
    e.preventDefault();
    const inputs = Array.from(document.getElementsByTagName('input'));
    inputs.some((el: any, i: number) => {
      if (el === e.target && inputs.length > i + 1) {
        window.scrollTo({ top: inputs[i + 1].offsetTop });
        inputs[i + 1].focus();
        return true;
      }
    });
  }
}
