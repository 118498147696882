
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import DistributorsStep from '@/components/flowSteps/DistributorsStep.vue';
import InqueryStep from '@/components/flowSteps/InqueryStep.vue';
import IntroStep from '@/components/flowSteps/IntroStep.vue';
import ConfigurationsOverviewStep from '@/components/flowSteps/ConfigurationsOverviewStep.vue';
import InqueryFinalized from '@/components/flowSteps/InqueryFinalized.vue';
import { initialInquery, initialStep, State } from '@/typing/state';
import storeTypes from '@/store/types';
import { IInquery } from '@/typing/inquery.interface';
import NathanModal from '@/components/NathanModal.vue';

@Component({
  components: {
    IntroStep,
    DistributorsStep,
    ConfigurationsOverviewStep,
    InqueryStep,
    InqueryFinalized,
    NathanModal,
  },
})
export default class ConfiguratorView extends Vue {
  showCancelConfirmModal = false;
  copyOfToEditInquery: IInquery | null = null;

  get currentStep(): string {
    return (this.$store.state as State).selectedConfiguratorStepName.step;
  }

  get currentStepOnload(): () => void {
    return (
      (this.$store.state as State).selectedConfiguratorStepName.onload ??
      (() => {
        return;
      })
    );
  }

  get currentInquery(): IInquery {
    return this.$store.state.inquery;
  }

  get isEditingInquery(): boolean {
    return !!this.currentInquery.id;
  }

  get showEditingBanner(): boolean {
    return this.isEditingInquery && this.currentStep !== 'inqueryFinalized';
  }

  get currentStepData(): any {
    return (this.$store.state as State).selectedConfiguratorStepName.stepData;
  }

  mounted(): void {
    if (this.currentInquery.id) {
      this.copyOfToEditInquery = { ...this.currentInquery };
    }
  }

  cancelEditInquery(): void {
    this.showCancelConfirmModal = false;
    this.$store.commit(storeTypes.mutations.SET_CONFIGURATIONS, []);
    this.$store.commit(storeTypes.mutations.SET_INQUERY, initialInquery);
    this.$store.commit(
      storeTypes.mutations.SET_SELECTED_CONFIGURATOR_STEP,
      initialStep
    );

    if (this.copyOfToEditInquery !== null) {
      this.$store.commit(
        storeTypes.mutations.SET_SELECTED_ADMIN_INQUERY,
        this.copyOfToEditInquery
      );
    }

    this.$router.push({ name: 'InqueryDetail' });
  }

  changeStep(e: any): void {
    this.$store.commit(storeTypes.mutations.SET_SELECTED_CONFIGURATOR_STEP, {
      step: e.step,
      stepData: e.data,
      onload: e.onload,
    });

    this.$forceUpdate();
  }
}
