import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Configurator from '@/views/Configurator.vue';
import InqueryOverview from '@/views/InqueryOverview.vue';
import InqueryDetails from '@/views/InqueryDetails.vue';
import MijnNathanLogin from '@/views/MijnNathanLogin.vue';
import NotFound from '@/views/404.vue';
import Forbidden from '@/views/403.vue';
import ComponentPlayground from '@/views/ComponentPlayground.vue';
import authGuard from '@/guards/auth.guard';
import { getState } from '@/utils/vueInstance';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'AuthLogin' },
  },
  {
    path: '/configurator',
    name: 'Configurator',
    component: Configurator,
    beforeEnter: (to: Route, from: Route, next: any) => {
      authGuard(to, from, next);
    },
  },
  {
    path: '/inquery/overview',
    name: 'InqueryOverview',
    component: InqueryOverview,
    beforeEnter: (to: Route, from: Route, next: any) => {
      authGuard(to, from, next);
    },
  },
  {
    path: '/inquery/detail',
    name: 'InqueryDetail',
    component: InqueryDetails,
    beforeEnter: (to: Route, from: Route, next: any) => {
      authGuard(to, from, next);
    },
  },
  {
    path: '/auth',
    name: 'AuthLogin',
    props: (route: Route) => ({ jwt: route.query.jwt }),
    component: MijnNathanLogin,
  },
  {
    path: '/componentPlayground',
    name: 'ComponentPlayground',
    component: ComponentPlayground,
    beforeEnter: (to: Route, from: Route, next: any) => {
      if (getState().environment?.devAccessToken) {
        next();
      } else {
        next({ name: '403' });
      }
    },
  },
  {
    path: '/403',
    name: '403',
    component: Forbidden,
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: { name: '404' },
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: TestComp,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
