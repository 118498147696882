





















import Component from 'vue-class-component';
import Vue from 'vue';
import Typeahead from '@/components/Typeahead.vue';

@Component({
  components: {
    Typeahead,
  },
})
export default class ComponentPlayground extends Vue {
  typeaheadValue = '';
}
