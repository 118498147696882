



















































import { Roles } from '@/enums/role.enum';
import { State } from '@/typing/state';
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent';
import NathanHelper from '@/utils/nathan-helper';

@Component({
  props: {
    inquery: {
      required: false,
    },
  },
})
export default class InqueryDetails extends BaseComponent {
  nathanHelper = NathanHelper;

  get isAdmin(): boolean {
    return (
      (this.$store.state as State).userInformation?.role === Roles.employee
    );
  }
}
