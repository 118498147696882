import {
  IConfiguration,
  IZone,
  IZoneGroup,
} from '@/typing/configuration.interface';
import { IInquery } from '@/typing/inquery.interface';
import { postInquery, putInquery } from '@/api/inqueries';
import NathanHelper from '@/utils/nathan-helper';
import { getInitialState } from '.';
import storeTypes from './types';
import { ISessionStorageSavedData, State } from '@/typing/state';
import { SessionStorageValues } from '@/enums/session-storages-values.enum';
import { ActionContext } from 'vuex';
import { getVueInstance } from '@/utils/vueInstance';
import ToastrHelper from '@/utils/toastr-helper';
import stepScrollDown from '@/utils/step-scroll-down-oninit';

const actions = {
  SAVE_USER_INPUT_TO_SESSION_STORAGE(context: any) {
    new State(context.state).saveUserInputToSessionStorage();
  },
  RETRIEVE_USER_INPUT_FROM_SESSION_STORAGE(context: any) {
    const savedStateJSON: string | null = sessionStorage.getItem(
      SessionStorageValues.SavedUserData
    );
    if (!savedStateJSON) return;

    const savedState: ISessionStorageSavedData = JSON.parse(savedStateJSON);

    context.commit(
      storeTypes.mutations.SET_CONFIGURATIONS,
      savedState.configurations
    );
    context.commit(storeTypes.mutations.SET_INQUERY, savedState.inquery);
    context.commit(
      storeTypes.mutations.SET_SELECTED_ADMIN_INQUERY,
      savedState.selectedAdminInquery
    );
    context.commit(
      storeTypes.mutations.SET_SELECTED_CONFIGURATOR_STEP,
      savedState.selectedConfiguratorStepName
    );
  },
  async UPDATE_SELECTED_INQUERY(
    context: ActionContext<State, any>,
    payload: IInquery
  ) {
    if (!context.state.selectedAdminInquery) return;

    context.dispatch(storeTypes.actions.TOGGLE_LOADER);
    try {
      await putInquery(payload, payload.configurations ?? []);
      ToastrHelper.successToastr('Wijzigingen opgeslagen.', getVueInstance());
      context.commit(storeTypes.mutations.SET_SELECTED_ADMIN_INQUERY, payload);
    } catch (error) {
      ToastrHelper.errorToastr(
        'Er is iets fout gegaan met het opslaan van de wijzigingen.',
        getVueInstance()
      );
      return error;
    } finally {
      context.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  },
  RESET_CONFIGURATOR_STATE(context: any) {
    context.commit(storeTypes.mutations.SET_CONFIGURATIONS, []);
    context.commit(storeTypes.mutations.SET_INQUERY, getInitialState().inquery);
  },
  EDIT_SELECTED_INQUERY(context: ActionContext<State, any>) {
    const selectedInquery = context.state.selectedAdminInquery;
    const configurations = [...(selectedInquery?.configurations ?? [])];
    configurations.forEach((c: IConfiguration) => (c.finishedFlow = true));

    context.commit(storeTypes.mutations.SET_CONFIGURATIONS, configurations);
    context.commit(storeTypes.mutations.SET_INQUERY, selectedInquery);
    context.commit(storeTypes.mutations.SET_SELECTED_CONFIGURATOR_STEP, {
      step: 'finalize',
      stepData: {},
      onload: stepScrollDown,
    });
  },
  CREATE_AND_SELECT_NEW_CONFIGURATION(context: any) {
    let configurations = [...context.state.configurations];

    if (!configurations) {
      configurations = [];
    }

    const newConfig: IConfiguration = {
      id: -Math.abs(context.state.configurations.length + 1),
      clientName: '',
      address: '',
      name: '',
      distributorType: undefined,
      amount: 1,
      zoneGroups: [],
      distributorUnitType: {
        type: undefined,
        inputDirection: undefined,
      },
      includingPump: undefined,
      flowRate: undefined,
      energySupplier: undefined,
      connection: undefined,
      addressKnown: undefined,
      currentlySelected: false,
    };

    if (configurations.length > 0) {
      const lastConfig: IConfiguration =
        configurations[configurations.length - 1];
      newConfig.address = lastConfig.address;
      newConfig.clientName = lastConfig.clientName;
      newConfig.name = lastConfig.name;
      newConfig.addressKnown = lastConfig.addressKnown;
      newConfig.lot = lastConfig.lot;
      newConfig.block = lastConfig.block;
      newConfig.houseNumber = lastConfig.houseNumber;
    }

    configurations.push(newConfig);
    context.commit(storeTypes.mutations.SET_CONFIGURATIONS, configurations);
    context.dispatch(
      storeTypes.actions.CHANGE_CURRENTLY_SELECTED_CONFIGURATION,
      newConfig.id
    );
  },
  CHANGE_CURRENTLY_SELECTED_CONFIGURATION(
    context: any,
    configurationId: number
  ) {
    const configurations = [...context.state.configurations];
    const configIndex = configurations.findIndex(
      (c: IConfiguration) => c.id === configurationId
    );

    if (configIndex > -1) {
      configurations.forEach(
        (c: IConfiguration) => (c.currentlySelected = false)
      );
      configurations[configIndex].currentlySelected = true;
      context.commit(storeTypes.mutations.SET_CONFIGURATIONS, configurations);
    }
  },
  MUTATE_CONFIGURATION_BY_ID(context: any, data: any) {
    const configurations = [...context.state.configurations];
    const configIndex = configurations.findIndex(
      (c: IConfiguration) => data.id === c.id
    );

    if (configIndex > -1 && data.config) {
      configurations.splice(configIndex, 1, data.config);

      context.commit(storeTypes.mutations.SET_CONFIGURATIONS, configurations);
    }
  },
  MUTATE_CURRENTLY_SELECTED_CONFIGURATION(
    context: any,
    config: IConfiguration
  ) {
    const configurations = [...context.state.configurations];
    const configIndex = configurations.findIndex(
      (c: IConfiguration) => c.id === config.id
    );

    if (configIndex > -1) {
      configurations.splice(configIndex, 1, config);
      context.commit(storeTypes.mutations.SET_CONFIGURATIONS, configurations);
    }
  },
  REMOVE_CONFIG(context: any, configId: number) {
    const configurations = [...context.state.configurations];
    const configIndex = configurations.findIndex(
      (c: IConfiguration) => c.id === configId
    );

    if (configIndex > -1) {
      configurations.splice(configIndex, 1);
      context.commit(storeTypes.mutations.SET_CONFIGURATIONS, configurations);
    }
  },
  MUTATE_INQUERY(context: any, inquery: IInquery) {
    context.commit(storeTypes.mutations.SET_INQUERY, inquery);
  },
  async POST_PUT_INQUERY(context: any) {
    const configurations: any[] = [...context.state.configurations]
      .filter((c: IConfiguration) => c.finishedFlow)
      .map((c: IConfiguration) => {
        return {
          id: c.id && c.id > 0 ? c.id : undefined,
          clientName: c.clientName,
          address: c.address ?? null,
          name: c.name,
          distributorType: c.distributorType,
          amount: c.amount,
          zoneGroups: c.zoneGroups,
          includingPump: c.includingPump,
          flowRate: c.flowRate,
          energySupplier: c.energySupplier,
          connection: c.connection,
          distributorUnitType: c.distributorUnitType,
          addressKnown: c.addressKnown,
          lot: c.lot ?? null,
          block: c.block ?? null,
          housenumber: c.houseNumber ?? null,
          articleNumber: '',
        };
      });

    if (configurations.length > 0) {
      configurations.forEach((c: IConfiguration) => {
        c.zoneGroups?.forEach((zg: IZoneGroup) => {
          zg.zones.forEach((z: IZone) => {
            z.name = NathanHelper.translateZoneNames(
              [z],
              getVueInstance()
            )[0].name;
          });
        });
      });

      const inquery: IInquery = context.state.inquery;
      const apiCall = (inquery as IInquery).id
        ? putInquery(inquery, configurations)
        : postInquery(inquery, configurations);
      try {
        const result = await apiCall;
        return result;
      } catch (error) {
        return error;
      }
    }
  },
  TOGGLE_LOADER(context: any) {
    context.commit(
      storeTypes.mutations.SET_SHOW_LOADER,
      !context.state.showLoader
    );
  },
};

export default actions;
