import { apiBaseUrl, environment, IEnvironment } from '@/utils/environment';
import Axios, { AxiosResponse } from 'axios';

export function dowloadInqueryById(id: any): Promise<any> {
    return Axios.get(`${(environment() as IEnvironment).apiBaseUrl}/v1/inquiries/${id}/pdf`);
}

export function getConfigurations(): Promise<AxiosResponse> {
    return Axios.get(`${apiBaseUrl()}/v1/configurations`);
}

export function getConfigurationById(id: number): Promise<AxiosResponse> {
    return Axios.get(`${apiBaseUrl()}/v1/configurations/${id}`);
}

export function getConfigurationStickerPDF(id: number): Promise<AxiosResponse> {
    return Axios.get(`${(environment() as IEnvironment).apiBaseUrl}/v1/configurations/${id}/sticker`);
}

export function getConfigurationsStickersPDF(id: number): Promise<AxiosResponse> {
    return Axios.get(`${(environment() as IEnvironment).apiBaseUrl}/v1/inquiries/${id}/stickers`);
}
