























































import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        amount: Number,
        max: Number
    }
})
export default class ZoneSegmentsAmountUI extends Vue {
    increase(): void {
        let amount = this.$props.amount;
        if ((amount + 1) <= this.$props.max) {
            amount++;
            this._emitChanges(amount);
        } else {
            this.$emit('exceededMaximum');
        }
    }

    decrease(): void {
        let amount = this.$props.amount;
        if ((amount - 1) > 0) {
            amount--;
            this._emitChanges(amount);
        } else {
            this.$emit('exceededMinimum');
        }
    }

    private _emitChanges(val: number): void {
        this.$emit('input', val);
        this.$emit('update', val);
    }
}
