

























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    suggestions: {
      type: Array,
      default: [],
    },
    value: {
      type: String,
      default: '',
    },
  },
})
export default class Typeahead extends Vue {
  suggestion: ITypeaheadSuggestion | null = null;

  mounted(): void {
    this.$watch(
      () => this.$props.value,
      (newValue: string, oldValue: string) => {
        if (!newValue && oldValue) {
          this.suggestion = null;
        } else {
          this.setSuggestion();
        }
      }
    );
  }

  setSuggestion(): void {
    const value = this.$props.value;

    if (!value || value.length <= 1) {
      this.suggestion = null;
      return;
    }

    const results = this.$props.suggestions.filter(
      (x: string) =>
        x.toLowerCase().startsWith(value.toLowerCase()) &&
        x.length > value.length
    );

    const suggestion = results.length ? results[0] : '';

    this.suggestion = {
      preservedCasing: this._fillRestWithPreservedCapitalization(
        value,
        suggestion
      ),
      suggestedCasing: suggestion,
    };
  }

  selectSelection(e: Event): void {
    if (!this.suggestion || !this.suggestion?.suggestedCasing) return;

    e.preventDefault();
    this.emit(this.suggestion.suggestedCasing);
    this.suggestion = null;
  }

  emit(value: string): void {
    this.$emit('input', value);
  }

  private _fillRestWithPreservedCapitalization(
    baseString: string,
    sourceString: string
  ): string {
    // Get the remaining characters of sourceString after baseString (disregarding capitalization)
    const remaining = sourceString.slice(baseString.length);

    return baseString + remaining;
  }
}

interface ITypeaheadSuggestion {
  suggestedCasing: string;
  preservedCasing: string;
}
