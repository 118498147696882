import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BaseComponent extends Vue {
  truncateText(s: string, length: number): string {
    if (s.length < length) {
      return s;
    }

    return s.slice(0, length - 3) + '...';
  }
}
