





















import Component from 'vue-class-component';
import Vue from 'vue';
import NathanModal from './NathanModal.vue';

@Component({
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  components: {
    NathanModal,
  },
})
export default class InformationButton extends Vue {
  showInfo = false;

  toggleInfo() {
    this.showInfo = !this.showInfo;
  }
}
