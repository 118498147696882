
















































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import RadioOptions from '@/components/RadioOptions.vue';
import { IConfiguration } from '@/typing/configuration.interface';
import ToastrHelper from '@/utils/toastr-helper';
import {
  ConnectionTypes,
  DistributorType,
  EnergySuppliers,
  FlowRates,
  InputDirections,
} from '@/enums/configuration-options.enum';
import storeTypes from '@/store/types';
import InformationButton from '../InformationButton.vue';
import ConfigurationHelper from '@/utils/configuration-helper';

@Component({
  components: {
    RadioOptions,
    InformationButton,
  },
})
export default class IntroStep extends Vue {
  dtEnum = DistributorType;

  get showDistributorTypeStep(): boolean {
    const config: any = this.currentConfiguration;
    return config.connection || config.flowRate || config.energySupplier;
  }

  get addressFilled(): boolean {
    if (this.currentConfiguration.addressKnown) {
      return !!this.currentConfiguration.address;
    } else {
      return (
        !!this.currentConfiguration.lot && !!this.currentConfiguration.block
      );
    }
  }

  currentConfiguration: IConfiguration = {} as any;

  addressKnownOptions = [
    {
      text: this.$t('no'),
      value: false,
      selected: false,
    },
    {
      text: this.$t('yes'),
      value: true,
      selected: false,
    },
  ];

  typeOfDistributerOptions = [
    {
      text: this.$t('option_labels.standard'),
      value: DistributorType.standard,
      selected: false,
    },
    {
      text: this.$t('option_labels.cityHeating'),
      value: DistributorType.cityHeating,
      selected: false,
    },
    {
      text: this.$t('option_labels.heatingAndCooling'),
      value: DistributorType.heatingAndCooling,
      selected: false,
    },
  ];

  energySupplierOptions = [
    {
      text: EnergySuppliers.vattenfallEneco,
      value: EnergySuppliers.vattenfallEneco,
      selected: false,
    },
    {
      text: EnergySuppliers.essent,
      value: EnergySuppliers.essent,
      selected: false,
    },
  ];

  distributorOptions = [
    {
      text: this.$t('option_labels.withoutPump'),
      value: false,
      selected: false,
    },
    {
      text: this.$t('option_labels.withPump'),
      value: true,
      selected: false,
    },
  ];

  flowRateOptions = [
    { text: FlowRates.low, value: FlowRates.low, selected: false },
  ];

  amountOfPipesOptions = [
    {
      text: this.$t('option_labels.threePipesConnection'),
      value: ConnectionTypes.threePipe,
      selected: false,
    },
    {
      text: this.$t('option_labels.fourPipesConnection'),
      value: ConnectionTypes.fourPipe,
      selected: false,
    },
  ];

  distributorTypeOptions = [
    {
      text: `4010 (${this.$t('left')})`,
      value: { inputDirection: InputDirections.left, type: '4010' },
      selected: false,
      imgName: '4010R.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.standard &&
        o.includingPump === false &&
        o.flowRate === FlowRates.low,
    },
    {
      text: `4010 (${this.$t('right')})`,
      value: { inputDirection: InputDirections.right, type: '4010' },
      selected: false,
      imgName: '4010.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.standard &&
        o.includingPump === false &&
        o.flowRate === FlowRates.low,
    },
    {
      text: `4800 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4800' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4800' },
      selected: false,
      imgName: '4800.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.standard &&
        o.includingPump === true &&
        o.flowRate === FlowRates.low,
    },
    {
      text: `4801 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4801' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4801' },
      selected: false,
      imgName: '4801.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.standard &&
        o.includingPump === true &&
        o.flowRate === FlowRates.low,
    },
    {
      text: `4885 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4885' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4885' },
      selected: false,
      imgName: '4885.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.cityHeating &&
        o.energySupplier === EnergySuppliers.essent,
    },
    {
      text: `4880 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4880' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4880' },
      selected: false,
      imgName: '4880.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.cityHeating &&
        o.energySupplier === EnergySuppliers.vattenfallEneco,
    },
    {
      text: `4850 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4850' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4850' },
      selected: false,
      imgName: '4850.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.heatingAndCooling &&
        o.connection === ConnectionTypes.threePipe,
    },
    {
      text: `4851 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4851' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4851' },
      selected: false,
      imgName: '4851.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.heatingAndCooling &&
        o.connection === ConnectionTypes.threePipe,
    },
    {
      text: `4860 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4860' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4860' },
      selected: false,
      imgName: '4860.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.heatingAndCooling &&
        o.connection === ConnectionTypes.fourPipe,
    },
    {
      text: `4861 (${ConfigurationHelper.getRealLifeInputDirectionByDistributorType(
        { type: '4861' }
      )})`,
      value: { inputDirection: InputDirections.left, type: '4861' },
      selected: false,
      imgName: '4861.png',
      showOption: (o: IConfiguration) =>
        o.distributorType === DistributorType.heatingAndCooling &&
        o.connection === ConnectionTypes.fourPipe,
    },
  ];

  private _configIsNew = false;
  private _isInitialized = false;

  async mounted() {
    if (this.$store.state.configurations.length === 0) {
      await this.$store.dispatch(
        storeTypes.actions.CREATE_AND_SELECT_NEW_CONFIGURATION
      );
    }

    this.currentConfiguration =
      this.$store.getters[
        storeTypes.getters.GET_CURRENTLY_SELECTED_CONFIGURATION
      ];

    if (!this.currentConfiguration?.distributorUnitType?.inputDirection) {
      this._configIsNew = true;
    }

    this.$watch(
      () => this.currentConfiguration?.distributorUnitType?.inputDirection,
      (o: any, n: any) => {
        if (!o) return;
        if (
          o !== n &&
          (this.currentConfiguration.zoneGroups ?? []).length > 0
        ) {
          (this.currentConfiguration.zoneGroups ?? []).forEach((x) =>
            x.zones.reverse()
          );
        }
      }
    );

    setTimeout(() => {
      this._isInitialized = true;
    }, 500);
  }

  checkOptions(optionName: string): void {
    if (this._isInitialized) {
      (this.currentConfiguration as IConfiguration).distributorUnitType = {
        type: undefined,
        inputDirection: undefined,
      };

      if (optionName === 'distributorType') {
        (this.currentConfiguration as IConfiguration).energySupplier =
          undefined;
        (this.currentConfiguration as IConfiguration).includingPump = undefined;
        (this.currentConfiguration as IConfiguration).connection = undefined;
        (this.currentConfiguration as IConfiguration).flowRate = undefined;
      }

      if (optionName === 'includingPump') {
        (this.currentConfiguration as IConfiguration).flowRate = undefined;
      }

      this.$forceUpdate();
    }
  }

  scrollToNext(optionName: string): void {
    if (!this._isInitialized || !this._configIsNew) return;

    const scrollToID = (id: string) => {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };

    if (optionName === 'client-name') {
      scrollToID('client-address-question');
    } else if (optionName === 'client-address-question') {
      scrollToID('config-address');
    } else if (optionName === 'client-address') {
      scrollToID('config-name');
    } else if (optionName === 'config-name') {
      scrollToID('distributorType');
    } else if (optionName === 'distributorType') {
      if (
        this.currentConfiguration.distributorType === DistributorType.standard
      ) {
        scrollToID('includingPump');
      } else if (
        this.currentConfiguration.distributorType ===
        DistributorType.cityHeating
      ) {
        scrollToID('energySupplier');
      } else if (
        this.currentConfiguration.distributorType ===
        DistributorType.heatingAndCooling
      ) {
        scrollToID('connection');
      }
    } else if (optionName === 'includingPump') {
      scrollToID('flowRate');
    } else if (
      optionName === 'connection' ||
      optionName === 'energySupplier' ||
      optionName === 'flowRate'
    ) {
      scrollToID('unitType');
    }
  }

  async submit() {
    if (this._canSubmit()) {
      await this.$store.dispatch(
        storeTypes.actions.MUTATE_CURRENTLY_SELECTED_CONFIGURATION,
        this.currentConfiguration
      );

      this.currentConfiguration = {} as any;

      this.$nextTick(() => {
        this.$emit('changeStep', { step: 'distributors' });
      });
    }
  }

  lastStepNumber(): number {
    if (this.currentConfiguration.flowRate) {
      return 7;
    }

    if (this.currentConfiguration.connection) {
      return 6;
    }

    if (this.currentConfiguration.energySupplier) {
      return 6;
    }

    return 0;
  }

  private _canSubmit(): boolean {
    const config: IConfiguration = this.currentConfiguration;
    const isFilled: any = (val: string): boolean => !!(val && val !== '');
    const onCheck: any = (returnValue: boolean) => {
      if (!returnValue)
        ToastrHelper.errorToastr(
          this.$t('intro_step_missing_values') as string,
          this
        );
      return returnValue;
    };

    if (
      !config.distributorType ||
      !isFilled(config.name) ||
      !isFilled(config.clientName) ||
      !this.addressFilled
    ) {
      return onCheck(false);
    }

    if (config.distributorType === 'standard') {
      return onCheck(
        (config.includingPump === true || config.includingPump === false) &&
          !!config.flowRate &&
          !!config.distributorUnitType?.type
      );
    }

    if (config.distributorType === 'cityHeating') {
      return onCheck(
        !!config.energySupplier && !!config.distributorUnitType?.type
      );
    }

    if (config.distributorType === 'heatingAndCooling') {
      return onCheck(!!config.connection && !!config.distributorUnitType?.type);
    }

    return false;
  }
}
