import { State } from "@/typing/state";

export const vueInstanceWindowLocation = '__app__';

export function getVueInstance(): Vue {
    return (window as any)[vueInstanceWindowLocation];
}

export function setVueInstance(vueInstance: any): void {
    (window as any)[vueInstanceWindowLocation] = vueInstance;
}

export function getState(): State {
    return getVueInstance().$store.state as unknown as State;
}