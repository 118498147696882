import Axios, { AxiosError, AxiosResponse } from 'axios';
import { EventBus, EventBusEvent } from './event-bus';
import ToastrHelper from './toastr-helper';
import { getVueInstance } from './vueInstance';

export function initInterceptor() {
  Axios.interceptors.request.use(
    config => {
      const jwt = getVueInstance().$store.state.jwt;

      if (jwt) {
        config.headers.Authorization = `Bearer ${jwt}`;
      }

      return config;
    },
    (error: AxiosError) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.data.errors) {
        const errors: string[] = [];

        error.response.data.errors.forEach((error: any) => {
          errors.push(
            getVueInstance()
              .$i18n.t(`api_errors.${error.code}`)
              .toString()
          );
        });

        if (errors.length > 0) {
          ToastrHelper.errorToastr(errors.join('\n\n'), getVueInstance());
        }
      }
      if (error.response?.status === 401) {
        EventBus.$emit(EventBusEvent.RefreshToken);
      }

      return Promise.reject(error);
    }
  );
}
