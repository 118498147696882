




























































































































































import NathanHelper from '@/utils/nathan-helper';
import Vue from 'vue';
import Component from 'vue-class-component';
import draggable from 'vuedraggable';
import ConfigurationSegment from './ConfigurationSegment.vue';

@Component({
  props: {
    zones: Array,
    rightsided: Boolean,
  },
  components: {
    draggable,
    ConfigurationSegment,
  },
})
export default class ConfigurationZone extends Vue {
  shownZones: any[] = [];

  private _zones: any[] = [];

  get zoneContainer(): Element | null {
    return (this.$refs.container as Element) ?? null;
  }

  mounted() {
    setTimeout(() => {
      if (this.$props.rightsided === true) {
        if (this.zoneContainer) {
          this.zoneContainer.scrollLeft = 99999;
        }
      }
    }, 500);

    if (this.$props.zones) {
      this._zones = [...this.$props.zones];
      this._setShownZones([...this.$props.zones]);
    }

    this.$watch(
      'zones',
      (newValue) => {
        let shouldScrollToLast = false;

        if (!this._zones) {
          shouldScrollToLast = true;
        } else {
          shouldScrollToLast = newValue.length > this.shownZones.length;
        }

        this._zones = newValue;
        this._setShownZones([...this._zones]);

        if (shouldScrollToLast) {
          this._scrollToLastZone();
        }
      },
      { deep: true }
    );

    this.$watch('rightsided', () => {
      this._setShownZones([...this._zones]);
    });
  }

  onScroll(): void {
    this.$emit('scrolledZones', (this.zoneContainer as any).scrollLeft ?? 0);
  }

  moveLeft(toMoveIndex: number): void {
    if (toMoveIndex === 0) {
      return;
    }

    NathanHelper.arrayMoveIndex(this.shownZones, toMoveIndex, toMoveIndex - 1);
    this.emitChanges();
  }

  moveRight(toMoveIndex: number): void {
    if (toMoveIndex === this.shownZones.length - 1) {
      return;
    }

    NathanHelper.arrayMoveIndex(this.shownZones, toMoveIndex, toMoveIndex + 1);
    this.emitChanges();
  }

  emitChanges(): void {
    const zones = [...this.shownZones];
    this.$emit('zonesChanged', [...zones]);
  }

  removeZone(index: number): void {
    this.shownZones.splice(index, 1);
    this.emitChanges();
  }

  containerScrollTo(scrollToPx: number): void {
    const container = this.zoneContainer;
    if (!container) return;
    if (!container.scroll) return;

    container.scroll({
      left: scrollToPx,
      behavior: 'smooth',
    });
  }

  private _scrollToLastZone(): void {
    if (!this.shownZones || this.shownZones.length === 0) return;
    this.$nextTick(() => {
      const lastZoneGroupIndex = this.$props.rightsided
        ? 0
        : this.shownZones.length - 1;

      if (lastZoneGroupIndex <= -1) return;

      const indexFromLastZoneInZoneGroup =
        this.shownZones[lastZoneGroupIndex].zones.length - 1;

      if (indexFromLastZoneInZoneGroup <= -1) return;

      const element: any =
        this.$refs[
          `zone-${lastZoneGroupIndex}-${indexFromLastZoneInZoneGroup}`
        ];

      if (!element || !element[0]) return;

      this.containerScrollTo(element[0].getClientRects()[0].x);
    });
  }

  private _setShownZones(zones: any[]): void {
    this.shownZones = [...zones];
  }
}
