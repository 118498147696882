import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

const $dayjs = dayjs;
$dayjs.extend(utc);
$dayjs.extend(customParseFormat);
$dayjs.extend(duration);
$dayjs.extend(isBetween);
$dayjs.extend(isSameOrAfter);
$dayjs.extend(isSameOrBefore);

export default $dayjs;

export function isTimeString(s: string): boolean {
  return $dayjs(s, 'HH:mm', true).isValid();
}

export function isDateString(s: string): boolean {
  return $dayjs(s, 'YYYY-MM-DD', true).isValid();
}

export function getTimeFromDateTime(s: string): string {
  return $dayjs
    .utc(s)
    .local()
    .format('HH:mm');
}

export function getDateFromDateTime(s: string): string {
  return $dayjs
    .utc(s)
    .local()
    .format('DD-MM-YYYY');
}

export function validateDateTime(
  date = '',
  time = '',
  customDateFormat?: string
): boolean {
  const dateValid = $dayjs(
    date,
    customDateFormat ? customDateFormat : 'YYYY-MM-DD',
    true
  ).isValid();
  const timeValid = $dayjs(time, 'HH:mm', true).isValid();

  return dateValid && timeValid;
}

export function createDateTime(
  date: string | undefined,
  time: string | undefined,
  customDateFormat?: string
): any {
  if (!date || !time) return undefined;
  if (!validateDateTime(date, time, customDateFormat)) return undefined;

  return $dayjs(
    `${date} ${time}`,
    `${customDateFormat ? customDateFormat : 'YYYY-MM-DD'} HH:mm`
  )
    .local()
    .format();
}
