















































import Vue from 'vue';
import Component from 'vue-class-component';
import storeTypes from '@/store/types';
import { dowloadInqueryById } from '@/api/configurations';

@Component({
  props: {
    stepData: {
      required: false,
    },
  },
})
export default class InqueryFinalized extends Vue {
  hasEdited = false;

  mounted(): void {
    this.hasEdited = !!this.$props.stepData.hasEdited;
  }

  async downloadOrder(): Promise<any> {
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    try {
      const pdf = await dowloadInqueryById(this.$props.stepData.id);
      window.open(pdf.data.url);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  back(): void {
    this.$store.dispatch(storeTypes.actions.RESET_CONFIGURATOR_STATE);
    this.$emit('changeStep', { step: 'intro' });
    this.$router.push({ name: 'InqueryOverview' });
  }
}
