












import { getState } from '@/utils/vueInstance';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class DevToolbar extends Vue {
  public switchingRoles = false;

  get envName(): string {
    return getState().environment?.envName ?? '';
  }

  get currentRole(): string {
    return getState().userInformation.role;
  }

  gotoComponentPlayground(): void {
    this.$router.push({ name: 'ComponentPlayground' });
  }

  logEnvironment() {
    this._readableConsoleLog(getState().environment);
  }

  logState() {
    this._readableConsoleLog(getState());
  }

  private _readableConsoleLog(val: any): any {
    console.log(
      JSON.parse(
        JSON.stringify(val, (key: any, value: any) =>
          value === undefined ? null : value
        )
      )
    );
  }
}
