








































import { Component, Vue } from 'vue-property-decorator';
import ConfigurationSegment from '@/components/Configuration/ConfigurationSegment.vue';
import { IConfiguration, IZoneGroup } from '@/typing/configuration.interface';

@Component({
  components: {
    ConfigurationSegment,
  },
  props: {
    configuration: {
      default: null,
    },
    zoneGroups: {
      default: Array,
      type: Array,
    },
    rightsided: Boolean,
    zoneConfiguratorSize: Number,
    currentScrollPosition: Number,
  },
})
export default class ConfigurationMobilePreview extends Vue {
  previewScale = 0;

  mounted() {
    this.previewScale = this._getPreviewScale();

    this.$watch('windowWidth', () => {
      this.previewScale = this._getPreviewScale();
    });
  }

  get shownZoneGroups(): IZoneGroup[] {
    const config: IConfiguration = this.$props.configuration;
    return config.zoneGroups ? config.zoneGroups : [];
  }

  get leftScrollPosition(): number {
    let value = this.$props.currentScrollPosition * this.previewScale;

    return value;
  }

  get windowWidth(): any {
    return this.$store.state.windowWidth;
  }

  get scaledViewPortSize(): number {
    if (this.previewScale === 0) return 0;

    return this.previewScale * (window.innerWidth - 40);
  }

  changeScrollPosition(e: any): void {
    const clickPosition = e.layerX;
    const centerClickPosition = clickPosition - this.scaledViewPortSize / 2;

    this.$emit('scrollZones', centerClickPosition / this.previewScale);
  }

  private _getPreviewScale(): number {
    if (!this.$refs.wrapper) return 0;

    if (
      (this.$refs.wrapper as any).scrollWidth === 0 ||
      this.$props.zoneConfiguratorSize === 0 ||
      !this.$props.zoneConfiguratorSize
    ) {
      return 0;
    }

    return (
      (this.$refs.wrapper as any).scrollWidth / this.$props.zoneConfiguratorSize
    );
  }
}
