

















































































import Vue from 'vue';
import Component from 'vue-class-component';
import NathanModal from './NathanModal.vue';

@Component({
  components: {
    NathanModal,
  },
})
export default class Header extends Vue {
  showInstruction = false;
  documentUrl = window.origin + '/Werkinstructie_multizoneverdeler.pdf';
}
