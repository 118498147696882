import { IConfiguration } from '@/typing/configuration.interface';
import { IInquery } from '@/typing/inquery.interface';
import { apiBaseUrl } from '@/utils/environment';
import Axios, { AxiosResponse } from 'axios';

export function postInquery(
  inquery: IInquery,
  configurations: IConfiguration[]
): Promise<AxiosResponse> {
  const body = inquery;
  inquery.configurations = configurations;

  return Axios.post(`${apiBaseUrl()}/v1/inquiries`, body);
}

export function putInquery(
  inquery: IInquery,
  configurations: IConfiguration[]
): Promise<AxiosResponse> {
  const body = inquery;
  inquery.configurations = configurations;

  return Axios.put(`${apiBaseUrl()}/v1/inquiries/${inquery.id}`, body);
}

export function getInqueries(
  search?: string,
  page?: string
): Promise<AxiosResponse> {
  return Axios.get(
    `${apiBaseUrl()}/v1/inquiries${search ? `?search=${search}` : ``}${
      page ? `&page=${page}` : ``
    }`
  );
}

export function getMyInqueries(
  search?: string,
  page?: string
): Promise<AxiosResponse> {
  return Axios.get(
    `${apiBaseUrl()}/v1/inquiries/my${search ? `?search=${search}` : ``}${
      page ? `&page=${page}` : ``
    }`
  );
}

export function dowloadInqueryById(id: any): Promise<AxiosResponse> {
  return Axios.get(`${apiBaseUrl()}/v1/inquiries/${id}/pdf`);
}
