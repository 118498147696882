import { SessionStorageValues } from '@/enums/session-storages-values.enum';
import storeTypes from '@/store/types';
import { refreshTokenUrl } from './environment';
import ToastrHelper from './toastr-helper';

export function refreshTokenOld(vueInstance: any) {
  setTimeout(() => {
    vueInstance.$store.dispatch(
      storeTypes.actions.SAVE_USER_INPUT_TO_SESSION_STORAGE
    );

    sessionStorage.setItem(
      SessionStorageValues.RouteBeforeRefresh,
      vueInstance.$router.currentRoute.name ?? ''
    );

    window.location.href = refreshTokenUrl();
  }, 200);
}

export function refreshToken(vueInstance: any) {
  ToastrHelper.warningToastr(
    'De authenticatie voor de multi zone configuratie is verlopen. even geduld a.u.b.',
    vueInstance
  );

  setTimeout(() => {
    const newWindow = window.open(refreshTokenUrl());

    if (!newWindow) {
        return;
    }

    let tries = 20;
    const pollJWT = () => {
        if (tries > 0) {
            const refreshedToken = localStorage.getItem('refreshedToken');
    
            if (refreshedToken) {
                localStorage.removeItem('refreshToken');
                newWindow.close();
                vueInstance.$store.commit(storeTypes.mutations.SET_JWT, refreshedToken);
            } else {
                setTimeout(() => {
                    pollJWT();
                }, 3000);
            }
            tries--;
        } else {
            refreshTokenOld(vueInstance);
        }
    };

    pollJWT();
  }, 200);
}
