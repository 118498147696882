


































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import ZoneSegmentsAmountUI from '@/components/Configuration/ZoneSegmentsAmountUI.vue';
import ConfigurationZone from '@/components/Configuration/ConfigurationZone.vue';
import ConfigurationZoneMobile from '@/components/Configuration/ConfigurationZoneMobile.vue';
import NathanHelper from '@/utils/nathan-helper';
import NathanModal from '@/components/NathanModal.vue';
import { IConfiguration, IZoneGroup } from '@/typing/configuration.interface';
import storeTypes from '@/store/types';
import ConfigurationMobilePreview from '@/components/Configuration/ConfigurationMobilePreview.vue';
import ToastrHelper from '@/utils/toastr-helper';
import ConfigurationHelper from '@/utils/configuration-helper';
import stepScrollDown from '@/utils/step-scroll-down-oninit';
import Typeahead from '../Typeahead.vue';

@Component({
  components: {
    ZoneSegmentsAmountUI,
    ConfigurationZone,
    ConfigurationZoneMobile,
    NathanModal,
    ConfigurationMobilePreview,
    Typeahead,
  },
})
export default class DistributorsStep extends Vue {
  currentConfiguration: IConfiguration | any = {};
  zoneName = '';
  amountOfZoneGroups = 1;
  segments: any[] = [];
  showExplainationModal = false;
  showEditNameModal = false;
  editedName = '';
  zoneConfiguratorSize = 1;
  zoneScrollPosition = 0;
  showAlertModal = false;
  alertMessage: string | null = null;
  maxAmountOfTotalGroups = 15;
  isMounted = false;

  get rightsided(): boolean {
    return this.currentConfiguration?.distributorUnitType?.inputDirection ===
      'right'
      ? true
      : false;
  }

  get configurationZone(): ConfigurationZone | null {
    return (this.$refs.configurationZone as ConfigurationZone) ?? null;
  }

  get zoneNameOptions(): any[] {
    let currentZoneNames = [];

    if (this.currentConfiguration?.zoneGroups) {
      currentZoneNames = this.currentConfiguration.zoneGroups.flatMap(
        (x: IZoneGroup) => x.zones.flatMap((y) => y.name)
      );
    }

    const predefinedZoneNameSuggestions = NathanHelper.getZoneNames().map(
      (zoneKey: string) => {
        const translationKey = `zone_configurator.zone_names.${zoneKey}`;
        const value = this.$t(`zone_configurator.zone_names.${zoneKey}`);
        return translationKey === value ? zoneKey : value;
      }
    );

    return [...currentZoneNames, ...predefinedZoneNameSuggestions];
  }

  get isMobile(): boolean {
    return this.$store.state.isMobile;
  }

  get amountOfGroupsUsed(): number {
    return NathanHelper.getAmountOfGroupsUsedFromConfiguration(
      this.currentConfiguration
    );
  }

  get maxZones(): number {
    return this.maxAmountOfTotalGroups - this.amountOfGroupsUsed;
  }

  get canAddZone(): boolean {
    if (!this.amountOfZoneGroups || this.amountOfZoneGroups <= 0) return false;
    if (this.maxZones - this.amountOfZoneGroups < 0) return false;
    if (!this.zoneName || this.zoneName.trim().length === 0) return false;

    return true;
  }

  mounted() {
    this.currentConfiguration =
      this.$store.getters[
        storeTypes.getters.GET_CURRENTLY_SELECTED_CONFIGURATION
      ];

    this.$nextTick(() => {
      this._setZoneConfiguratorSize();
      this.isMounted = true;
    });
  }

  scrollToPosition(scrollPosition: number): void {
    (
      this.$refs.configurationZoneMobile as ConfigurationZoneMobile
    ).containerScrollTo(scrollPosition);
  }

  addZone(): void {
    if (this.zoneName && this.amountOfZoneGroups > 0) {
      const newZoneGroup: IZoneGroup = { zones: [] };

      const toAddZones = ConfigurationHelper.determineGroupsToAdd(
        this.amountOfZoneGroups
      );

      toAddZones.forEach((groupsInZone: number) => {
        newZoneGroup?.zones?.push({
          name: this.zoneName,
          amount: groupsInZone,
        });
      });

      if (this.rightsided) {
        newZoneGroup.zones.reverse();
        (this.currentConfiguration as IConfiguration).zoneGroups?.splice(
          0,
          0,
          newZoneGroup
        );
      } else {
        (this.currentConfiguration as IConfiguration).zoneGroups?.push(
          newZoneGroup
        );
      }

      this.zoneName = '';
      this.amountOfZoneGroups = 1;
    }
  }

  removeItem(itemIndex: number): void {
    this.currentConfiguration.zones.splice(itemIndex, 0);
  }

  gotoFinalizeStep(): void {
    if (this.amountOfGroupsUsed === 0) {
      // TODO: translate
      ToastrHelper.warningToastr(
        'Het is verplicht minstens een zone aan een configuratie toe te voegen.',
        this
      );
      return;
    }

    this._saveConfigurationChanges();
    this.$emit('changeStep', { step: 'finalize', onload: stepScrollDown });
  }

  gotoIntroStep(): void {
    this._saveConfigurationChanges();
    this.$emit('changeStep', { step: 'intro' });
  }

  openRenameConfigModal(): void {
    this.editedName = this.currentConfiguration.name;
    this.showEditNameModal = true;
  }

  closeEditNameModal(saveChanges: boolean): void {
    if (saveChanges) {
      if (this.editedName.length > 0) {
        this.currentConfiguration.name = this.editedName;
      }
    } else {
      this.editedName = this.currentConfiguration.name;
    }

    this.showEditNameModal = false;
  }

  handleExceededMinimum(): void {
    // TODO: Translate
    this.showAlertModal = true;
    this.alertMessage = 'U kunt niet minder dan 1 groep toevoegen.';
  }

  handleExceededMaximum(): void {
    this.showAlertModal = true;
    this.alertMessage = 'U heeft het maximale aantal groepen bereikt.';
  }

  private _setZoneConfiguratorSize(): void {
    const el: Element | any = this.$refs.configurationZoneMobile;

    if (!el || !el.$el) {
      this.zoneConfiguratorSize = 0;
      return;
    }

    this.zoneConfiguratorSize = el.$el.scrollWidth;
  }

  private _saveConfigurationChanges(): void {
    const configuration: IConfiguration =
      this.$store.getters[
        storeTypes.getters.GET_CURRENTLY_SELECTED_CONFIGURATION
      ];
    if (!configuration.finishedFlow) {
      configuration.finishedFlow = true;
    }
    this.$store.dispatch(
      storeTypes.actions.MUTATE_CURRENTLY_SELECTED_CONFIGURATION,
      configuration
    );
  }
}
