
























































































































import {
  dowloadInqueryById,
  getInqueries,
  getMyInqueries,
} from '@/api/inqueries';
import { IInquery } from '@/typing/inquery.interface';
import { State } from '@/typing/state';
import { Roles } from '@/enums/role.enum';
import storeTypes from '@/store/types';
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent';
import { getDateFromDateTime, getTimeFromDateTime } from '@/utils/dayjs';

@Component({
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
})
export default class InqueryOverview extends BaseComponent {
  inqueries: IInquery[] | null = null;
  searchTerms = '';
  hasSearched = false;
  isSearching = false;

  mounted() {
    if (!this.isAdmin) {
      this.search();
    }
  }

  get isAdmin(): boolean {
    return (
      (this.$store.state as State).userInformation?.role === Roles.employee
    );
  }

  getInqueryCreatedDate(i: IInquery): string {
    return `${getDateFromDateTime(i.createdUtc ?? '')} ${getTimeFromDateTime(
      i.createdUtc ?? ''
    )}`;
  }

  getInqueryModifiedDate(i: IInquery): string {
    return `${getDateFromDateTime(i.modifiedUtc ?? '')} ${getTimeFromDateTime(
      i.modifiedUtc ?? ''
    )}`;
  }

  async search(): Promise<any> {
    this.isSearching = true;
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    try {
      let result = null;
      if (this.isAdmin) {
        result = await getInqueries(this.searchTerms);
      } else {
        result = await getMyInqueries(this.searchTerms);
      }
      this.inqueries = result.data.results;
    } catch (error) {
      console.error(error);
    } finally {
      this.hasSearched = true;
      this.isSearching = false;
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  viewOrder(inquery: IInquery): void {
    this.$store.commit(
      storeTypes.mutations.SET_SELECTED_ADMIN_INQUERY,
      inquery
    );
    this.$router.push({ name: 'InqueryDetail' });
  }

  async downloadPDF(inqueryId: number): Promise<any> {
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    try {
      const pdf = await dowloadInqueryById(inqueryId);
      window.open(pdf.data.url);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  createOrderNumber(inqueryId: number) {
    const s = '000000000' + inqueryId;
    return 'A' + s.substr(s.length - 5);
  }

  newInquery(): void {
    this.$router.push({ name: 'Configurator' });
  }
}
