export default async function loadEnvironment(): Promise<any> {
    const envConfig = await fetch(`${window.location.origin}/environments/env.json`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return await envConfig.json();
}