import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales: any = {
    en: () => require('@/locales/en.json'),
    nl: () => require('@/locales/nl.json'),
  };

  const messages: LocaleMessages = {};
  Object.keys(locales).forEach((key: string) => {
    messages[key] = locales[key]();
  });

  return messages;
}

export default new VueI18n({
  locale: 'nl',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
  modifiers: {
    capitalize: (str: string) =>
      str
        .split('')
        .splice(0, 1, str[0].toLowerCase())
        .join(''),
  },
});
